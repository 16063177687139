<section class="lt-section-index">
  <div class="row justify-content-center">
      <div class="col-sm-6 col-md-6 col-lg-6 p-4">
        <div class="lt-index-section-left-content-div">
          <p class="lt-index-section-content"><b>>_404</b></p><h4>UH OH! You're lost.</h4>
          <p>
            The page you are looking for does not exist. How you got here is a mystery. But you can click the button below to go back to the homepage. </p>
            <a href="#">Go 127.0.0.1(Home)</a>
        </div>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-6 p-4">
          <div class="lt-index-section-right-content-div">
            <img src="./../../../../assets/images/bg/bg-left.png" class="lt-index-section-img" alt="Full Stack Developer">
          </div>
        </div>        
    </div> 
</section>