<section class="lt-section-index">
    <app-header></app-header>
    <div class="row justify-content-center">
        <div class="col-sm-6 col-md-6 col-lg-6 p-4">
          <div class="lt-index-section-left-content-div">
            <p class="lt-index-section-content"><b>>_Think twice</b> Code once.</p>
          </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6 p-4">
            <div class="lt-index-section-right-content-div">
              <img src="./../../../../assets/images/bg/bg-left.png" class="lt-index-section-img" alt="Full Stack Developer">
            </div>
          </div>        
      </div> 
</section>
<section id="about" class="lt-section-about"><br><br><br>
    <h3 class="text-center pt-4"><u>About</u></h3>
    <div class="row justify-content-left">
        <div class="col-sm-6 col-md-6 col-lg-6 p-4">
          <div class="lt-about-section-left-content-div">
            <img class="img-thumbnail lt-about-section-img" src="./../../../../assets/images/bg/about.png" alt="Linto Thomas">
          </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6 p-4">
            <div class="lt-about-section-right-content-div text-left">
                <h5>Linto Thomas</h5>
                <p class="lt-about-section-content">Senior Engineer</p>
                <p>A Web Developer by passion and profession who works in  AngularJs, NodeJs, Bootstrap, HTML, Ionic and also working in Blockchain Technologies, like Etherum, Stellar and EOS. </p>
                <p>An MCA graduate who is so passionate on discovering new things, and creating innovations for the better future.</p>
                <p>A nature lover who love agriculture and the natural beauty it provide.</p>
                <p>
                    <i class="fa fa-envelope">&nbsp;&nbsp;</i>vellackallintothomas@gmail.com<br/>
                    <i class="fa fa-phone">&nbsp;&nbsp;</i>+91-9846047279<br/>
                    <i class="fa fa-skype">&nbsp;&nbsp;</i>vellackallintothomas<br/>
                </p>
                <p>
                    <a href="https://github.com/mstars" target="_blank"><i class="fa fa-github"></i></a>
                    <a href="https://www.linkedin.com/in/capcod3r" class="pl-3" target="_blank"><i class="fa fa-linkedin "></i></a>
                    <a href="https://www.facebook.com/capcod3r" class="pl-3" target="_blank"><i class="fa fa-facebook"></i></a>
                    <a href="https://twitter.com/MilleniumStars" class="pl-3" target="_blank"><i class="fa fa-twitter"></i></a>
                    <a href="https://www.instagram.com/4chayan" class="pl-3" target="_blank"><i class="fa fa-instagram"></i></a>
                </p>
            </div>
          </div>        
      </div> 
</section>

<section id="timeline" class="lt-section-timeline"><br><br><br>
    <h3 class="text-center pt-4"><u>Time Line</u></h3>
    <div class="row justify-content-center">
        <div class="col-sm-6 col-md-6 col-lg-6 p-4 text-center">
          <div class="lt-timeline-section-left-content-div text-left">
            <h5>Experience</h5>
            <p># Qburst Technologies</p>
            <p class="lt-about-section-content">Senior SOftware Engineer</p>
            <p class="lt-about-section-content">Mar 2020 - Present</p>
            <p>QBurst is a global product development and consulting company offering cognitive solutions and custom software development services for micro to large enterprises.</p>
            <p>We are an end-to-end solution provider and R&D partner for many businesses. We provide robust digital solutions with enhanced user experience while making the development process enjoyable for clients and employees alike.</p>
            <p>Our vision is to be the most sought-after digital services company for technology innovation and custom software development.</p>
            <p># NetObjex Inc</p>
            <p class="lt-about-section-content">Full Stack Engineer</p>
            <p class="lt-about-section-content">Oct 2018 - Mar 2020</p>
            <p>NetObjex, Inc. based in California, USA is a Cognitive Automation Platform using IoT/AI/Blockchain. It is a Platform-as-a-Service provider with applications in Manufacturing, Supply Chain, Transportation and Smart Cities.</p>
          </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6 p-4">
            <div class="lt-timeline-section-right-content-div text-left">
                
                <h5>Accademics</h5>
                <p># Master of Computer Application- MCA</p>
                <p class="lt-about-section-content">Mar Athanasius College for Advanced Studies, Thiravalla (2016-18)</p>
                <p>Active in college volleyball team , 
                    Active member of computer society of India, 
                    Participated and won many prizes in IT fest conducted by various colleges,
                    Conducted Technitrous, National level IT fest in the year 2017 and 2018, 
                    Head of invitation committee in Technitrous  2018,  
                    Conducted a workshop on Python</p>
                
                    <p># Bachelor of Computer Application - BCA</p>
                    <p class="lt-about-section-content">St Berchmans College, Changanssery (2013-16)</p>
                    <p>Active in college volleyball team , 
                        Active member of computer society of India, 
                        Participated and won many prizes in IT fest conducted by various colleges</p>                    
            </div>
          </div>        
      </div> 
</section>
<section id="expertise" class="lt-section-expertise"><br><br><br>
    <h3 class="text-center pt-4"><u>Expertise</u></h3>
    <div class="row justify-content-center pl-4 pr-4 pt-4">
        <div class="col-sm-3 col-md-3 col-lg-3">
            <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Web Designing</h5>
                  <p class="card-text">Worked with technologies like HTML5,CSS3,Bootstrap 4,Java script.</p>
                </div>
            </div>    
        </div>  
        <div class="col-sm-3 col-md-3 col-lg-3">
            <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Web Development</h5>
                  <p class="card-text">Worked with technologies like AngularJs,ReactJs,VueJs,NodeJs,Ionic.</p>
                </div>
            </div>    
        </div>  
        <div class="col-sm-3 col-md-3 col-lg-3">
            <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Blockchain</h5>
                  <p class="card-text">Worked with technologies like Etherum(Solidity),Stellar and Dreamchain(EOS clone).</p>
                </div>
            </div>    
        </div>  
    </div>
    <div class="row justify-content-center pl-4 pr-4 pt-2">
        <div class="col-sm-3 col-md-3 col-lg-3">
            <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Penetration Testing</h5>
                  <p class="card-text">Have a good interest in Cyber security and have have done penetration testing.</p>
                </div>
            </div>    
        </div>  
        <div class="col-sm-3 col-md-3 col-lg-3">
            <div class="card">
                <div class="card-body">
                  <h5 class="card-title">API Development</h5>
                  <p class="card-text">Worked with technologies like Express(Node),Loopback(Node) and have idea on microservices.</p>
                </div>
            </div>    
        </div>  
        <div class="col-sm-3 col-md-3 col-lg-3">
            <div class="card">
                <div class="card-body">
                  <h5 class="card-title">Mobile Development</h5>
                  <p class="card-text">Worked with Ionic and also converted app work from AngularJs to crossplatform app.</p>
                </div>
            </div>    
        </div>  
    </div>
</section>
