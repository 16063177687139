<nav class="navbar fixed-top navbar-expand-lg navbar-dark bg-dark shadow p-3 mb-5 bg-dark">
    <a class="navbar-brand" href="#"><strong>Linto Thomas</strong></a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
      <ul class="navbar-nav ml-auto mt-2 mt-lg-0">
        <li class="nav-item">
          <a class="nav-link" href="#">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#about">About</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#timeline">TimeLine</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#expertise">Expertise</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="./../../../../assets/resume/Linto_Thomas.pdf" target="_blank">Resume</a>
        </li>
      </ul>
    </div>
</nav>
